#charge-container-outer {
  height: calc(100vh - 140px);
}
#container {
  height: 100%;
}
.charge-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*  padding: 0 20px;*/
  box-sizing: border-box;
  overflow: hidden;
  background: #fff;
}

.method_area {
  position: relative;
  transition: all 0.5s ease;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0px 10px 40px -30px rgba(0, 0, 0, 0.69);
  /*  height: 300px;*/
}
.step {
  display: none;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 20px;
  box-sizing: border-box;
}
.step.active {
  display: block;
}
.step.in {
  animation: slideInRight 0.5s forwards;
}
.step.previous {
  opacity: 1;
  display: block;
}
.step.out {
  animation: slideOutLeft 0.5s forwards;
}
.step.prev-out {
  animation: slideOutRight 0.5s forwards;
}

.step.prev-in {
  animation: slideInLeft 0.5s forwards;
}
.danger {
  color: #ff0b0b;
}
@keyframes slideOutLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slideOutRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
.tit_area {
  margin: 0;
  /*    padding: 20px 0;*/
  margin-bottom: 20px;
  font-size: 18px;
}

.link {
  padding: 10px 5px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  font-weight: 700;
  color: rgb(25, 31, 40);
  flex: 0 0 25%;
  max-width: 50%;
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.link.active {
  border: 1px solid rgb(25, 31, 40);
}
.option-icon {
  width: 35px;
}
.readme ul {
  margin-top: 0;
}
.form-with-unit p.input-note, .form-with-unit label.error-label {
  margin: 0;
}
.input-note.amount-result {
  font-size: 18px;
  font-weight: bold;
}

.tutorial-area {
  background: #f2f2f2;
  padding: 10px;
}
.tutorial-area h2 {
  margin-bottom: 15px;
}
.tutorial-area p {
  margin: 10px 0;
  font-size: 13px;
}
.tutorial-area a {
  color: #3474b5;
}

.deposit-info-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}
.wallet-address {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.wallet-area {
  margin: 0 auto;
  font-size: 14px;
}
a#btn-copy {
  margin-left: 8px;
}
div#timer {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0;
}
.tooltip {
  position: relative;
  display: inline-block;
  /*border-bottom: 1px dotted black;*/
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.qr-section {
  width: 40%;
}
.button-group {
  margin-bottom: 15px;
  padding: 0 20px;
  -webkit-box-shadow: 0px -10px 60px -30px rgba(0, 0, 0, 0.69);
  -moz-box-shadow: 0px -10px 60px -30px rgba(0, 0, 0, 0.69);
  box-shadow: 0px -10px 60px -30px rgba(0, 0, 0, 0.69);
}
.payment-order strong {
  margin-right: 5px;
}