.NavSection {
    position: fixed;
    bottom: 0;
    /*height: 85px;*/
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    z-index: 10;
}

/*.footerNotice {*/
/*    font-family: YoonGothicPro, 맑은고딕, Gulim, dotum, 돋음, sans-serif;*/
/*    font-weight: 500;*/
/*    letter-spacing: -1px;*/
/*    font-size: 14px;*/
/*    margin: -15px 0;*/
/*}*/

.footerNotice {
    font-family: YoonGothicPro, 맑은고딕, Gulim, dotum, 돋음, sans-serif;
    font-weight: 500;
    letter-spacing: -1px;
    font-size: 14px;
     /*margin: -15px 0; */
    /*margin: 65px 0;*/
    /*margin: 27% 0;*/
    /*margin-top: -30px;*/
    background: #f2f2f7;
}

#nav-wrap {
    /* position: fixed; */
    bottom: 0;
    left: 0;
    background-color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 55px;
    width: 100%;
    z-index: 100;
    border-top: 1px solid rgba(230, 232, 234, 0.5);
    box-sizing: border-box;
/*    background: url('/images/mobile_christmas_banner.png') no-repeat center center;*/
    background-size: cover;
}

#wrap {
    /*padding-bottom: 85px;*/
}

/*.footerNotice .wrapper {*/
/*    height: 70px;*/
/*    line-height: 70px;*/
/*}*/

.footerNotice .wrapper {
    height: 30px;
    line-height: 30px;
    display: flex;
    /* flex-direction: column; */
}

.footerNotice .wrapper:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
}

.footerNotice .wrapper > div {
    display: inline-block;
    /*vertical-align: middle;*/
}


.footerNotice .wrapper > div:first-child {
    /*display: block;*/
    /* vertical-align: middle; */
    display: flex;
    flex-direction: row;
    height: 30px;
    align-items: center;
    background: #f2f2f7;
}


.footerNotice .wrapper > div:first-child > a{
    line-height: 1 !important;
    width: 55px;
}

.footerNotice .wrapper > div:first-child > a > span {
    line-height: unset;
}

.footerNotice .symbolImg {
    width: 16px;
    height: 16px;
    margin: 0 10px;
    margin-top: 1px;
    display: inline-block;
    vertical-align: middle;
    background: url(https://image.named.com/portal/new_sp_main_icons.png) -120px
    0px /300px 300px;
}

.footerNotice .arrowImg {
    margin: 0 10px;
    width: 6px;
    height: 11px;
    display: inline-block;
    background: url("https://image.named.com/portal/new_sp_main_icons.png") -20px -90px /300px
    300px;
}

.footerNotice .topBtn {
    float: right;
    margin-right: 30px;
    cursor: pointer;
}

.footerNotice .topBtn .icon_top_btn {
    margin-top: 25px;
    height: 21px;
    width: 17px;
    background: url("https://image.named.com/portal/new_sp_main_icons.png") -185px -30px /300px
    300px;
}

/*.noticeList {*/
/*    font-family: YoonGothicPro, Gulim, 맑은고딕, dotum, 돋음, sans-serft;*/
/*    font-size: 12px;*/
/*    color: #333333;*/
/*    display: inline-block;*/
/*    height: 22px;*/
/*    width: 450px;*/
/*    overflow: hidden;*/
/*    vertical-align: middle;*/
/*}*/

.noticeList {
    font-family: YoonGothicPro, Gulim, 맑은고딕, dotum, 돋음, sans-serft;
    font-size: 12px;
    color: #333333;
    display: inline-block;
    height: 30px;
    width: 255px;
    overflow: hidden;
    vertical-align: middle;
}

.noticeList ul {
    display: block;
    width: 340px;
    position: relative;
    height: 100%;
    left: 0px;
    top: 0px;
    transition: 0.5s all;
}

.noticeList ul li.in {
    top: 0px;
    transition: 0.5s all ease-in;
    z-index: 10;
}

.noticeList ul li.out {
    top: -50px;
    transition: 0.5s all ease-out;
}

.noticeList ul li {
    top: 25px;
    position: absolute;
}

.noticeList ul li:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.noticeSubject {
    display: inline-block;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
