.bet-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #F2F2F7;
    padding: 10px;
    border: 1px solid #CECECE;
    margin-bottom: 10px;
    color: #A5A5A5;
    position: relative;
}
button.bet-button {
    background: #FF1517;
    width: 125px;
    height: 50px;
    color: #fff;
    font-weight: bold;
    font-family: Noto Sans KR, serif;
    font-size: 22px;
    line-height: 50px;
}
button.bet-button.loading {
    background: #a9a9a9;
    border-radius: 0;
}
p.bet-description {
    margin-right: 15px;
    width: calc(100% - 125px);
}
span.bet-highlight {
    font-weight: bold;
    color: rgb(215, 15, 15);
    line-height: 1.6;
}
.bet-description, .bet-button {
    z-index: 1;
}
.not-available {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(1px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #333;
    z-index: 2;
    font-weight: bold;
}

