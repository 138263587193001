.wallet-address {
  position: relative;
  display: flex;
  align-items: center;
}

.highlight {
  background-color: yellow; /* Bôi đen văn bản */
  padding: 0 5px; /* Thêm một chút padding */
}

.tooltip {
  position: relative;
  cursor: pointer;
  margin-left: 10px; /* Khoảng cách giữa nút và văn bản */
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Hiển thị ở trên nút */
  left: 50%;
  margin-left: -60px; /* Để căn giữa tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext,
.tooltip .tooltiptext {
  visibility: visible;
  opacity: 1; /* Hiển thị tooltip khi hover hoặc khi có tooltipVisible */
}