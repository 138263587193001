.banner {
  position: relative;
  width: 100%;
  height: 38px;
/*  overflow: hidden;*/
}

.banner-image {
  position: absolute;
  opacity: 0;
  transition: opacity 1s;
}

.banner-image.active {
  opacity: 1;
}

.button-group-banner.active {
  opacity: 1 !important;
  z-index: 10;
}

.hide-button {
  display: none !important;
}
