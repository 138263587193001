body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
}

.exchange-form {
    max-width: 500px;
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.red-text {
    color:  #e82d0c;
}

.gray-text {
    color: #918c8a;
}

.title {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-size: 18px;
}

.section {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.section-title {
    font-weight: bold;
    margin-bottom: 10px;
}

.info {
/*    display: flex;*/
/*    justify-content: space-between;*/
    font-weight: bold;
    color: #333;
    padding: 10px 0;
    margin-bottom: 20px;
}

.main-infor-item {
    font-size: 16px;
}
.info-item {
    margin-top: 10px;
}

#user-medal-amount {
    margin-left: 10px;
}

.form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.form-group input.box-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.submit-button {
    padding: 12px;
    background-color: #e82d0c;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    flex: 1;
    margin-right: 10px;
}

/*.submit-button:hover {*/
/*    background-color: #bf2408;*/
/*}*/

.cancel-button {
    padding: 12px;
    background-color: #ffffff;
    color: #000;
    border: 1px solid #cbc9c9;
    border-radius: 4px;
    cursor: pointer;
    flex: 1 1;
}

/*.cancel-button:hover {*/
/*    background-color: #c82333;*/
/*}*/

button.loading-btn {
    background: #ddd;
    cursor: not-allowed;
    border: 1px solid #ddd;
    color: #fff;
}

.disclaimer {
    margin-top: 20px;
    font-size: 0.9rem;
    color: #666;
}

input[type="checkbox"] {
    appearance: auto;
    margin-right: 5px;
    width: 15px;
    height: 15px;
}

.input-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

label.error-label, p.error-label {
    color: #cf0000;
    margin-bottom: 0;
    font-size: 12px;
}

.hide {
    display: none !important;
}

p.input-note {
    text-align: right;
}

.input-with-unit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
}

.form-with-unit p.input-note, .form-with-unit label.error-label {
    margin-right: 25px;
}

.input-without-unit {
    margin-top: 5px;
}

span.input-unit {
    margin-left: 10px;
}

.sum {
    font-weight: bold;
}
#actual_receipts {
    color: #e82d0c;
    font-size: 26px;
    margin-left: 20px;
    vertical-align: middle;
    font-weight: bold;
}
.cnt_unit {
    color: #e82d0c;
    vertical-align: middle;
    padding-right: 22px;
    margin-left: 3px;
}
div#receipts-result {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.readme {
    margin-top: 5px;
    padding: 15px;
    border: 1px solid #d6d6d6;
}
.readme p {
    font-weight: bold;
    color: #333;
}
.readme ul {
    margin-top: 17px;
}
.readme li.ico_indent {
    position: relative;
    padding-left: 20px;
    font-size: 13px;
}

.readme li.ico_indent::before {
    content: '';
    position: absolute;
    left: 0;
    top: 20%;
    width: 5px;
    height: 5px;
    background-color: #afafaf;
    border-radius: 50%;
}

/*Medal Exchange History*/
.medal_history_table {
    border: 1px solid #d6d6d6;
    font-size: 12px;
    width: 100%;
    text-align: center;
}
.medal_history_table th {
    font-weight: bold;
    font-size: 13px;
    padding: 15px 5px;
}
.medal_history_table td {
    font-size: 12px;
    padding: 5px;
}
td.flex-column {
    display: flex;
    align-items: center;
    justify-content: center;
}
span.exchange-status {
    width: 45px;
    display: flex;
    padding: 3px 0;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 5px;
}
span.exchange-status.ready {
    background: #666666;
}
span.exchange-status.complete {
    background: #cc3300;
}
span.exchange-status.progress {
    background: #1572af;
}
span.exchange-status.reject {
    background: #a1a1a1;
}
table thead {
    background: #f5f5f5;
    border-bottom: 1px solid #dddddd;
}
td.empty-row {
    height: 24vh;
}
.btn_back {
    position: absolute;
    top: 0;
    left: 0;
    width: 41px;
    height: 54px;
    line-height: 54px;
    text-indent: -9999px;
    overflow: hidden;
}
.btn_back:before {
    float: left;
    margin: 18px 0 0 15px;
    content: '';
    width: 11px;
    height: 18px;
    background: url(/images/sp_layout.png) no-repeat -140px 0;
    background-size: 350px 350px;
}